import React, {Component} from "react";
import Loader from "./Loader";
import "./scss/embed.css";

export interface EmbedDetailProps {
    name: string,
    progressBar: number,
    loaded: boolean
    imageIndex: number,
    current: string,
    next: string
}
export class EmbedDetail extends Component<EmbedDetailProps> {

  render() {

      let percent:number = this.props.progressBar ? this.props.progressBar : 0;
      if (percent > 100)
          percent = 100;

      let loaderElem = this.props.loaded ? null : (
          <Loader
              size="large"
              style={{
                  display: "block",
                  position: "absolute",
                  top: "calc(50% - 50px)",
                  left: "calc(50% - 50px)"
              }}
          />
      );

      let progressElem = percent >= 100 ? null : (
          <div className="progress" style={{ borderRadius: 0 }}>
              <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={percent}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: `${percent}%` }}
              />
          </div>
      )

      return (
          <div id="mainEmbedPlayerImage">
              {loaderElem}
              {progressElem}

              <span id="mainImageCurrent" style={{backgroundImage: "url("+this.props.current+")"}} />
              <span id="mainImageNext" style={{backgroundImage: "url("+this.props.next+")"}} />
          </div>
      )
  }
}
