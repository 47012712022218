import React, {Component} from "react";
import {
    faAngleDoubleDown, faAngleDoubleUp, faCompress,
    faExpand,
    faPause,
    faPlay,
    faVolumeDown,
    faVolumeMute,
    faVolumeUp
} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EmbedDetail}  from "./EmbedDetail";
import {ModalEmbedDetail} from "./ModalEmbedDetail";
import {get} from "lodash";
import "./scss/style.scss";
import "./scss/embed.css";
import {WatchData,  TimerIntervalSet, EmbedImage} from "./interfaces";
import * as axios from "axios";
import Loader from "./Loader";


library.add(faVolumeMute);
library.add(faVolumeUp);
library.add(faVolumeDown);
library.add(faPlay);
library.add(faPause);
library.add(faExpand);
library.add(faAngleDoubleDown);
library.add(faAngleDoubleUp);
library.add(faCompress);


//const timerInterval = 1000;
//const timerIntervalSet = [250, 500, 1000, 2000, 3000, 4000, 5000, 6000];

// TODO: 1. Add check the toggle button since it forces the thing to play again.
//       2. Add logic to reset the internal component state.
//       3. Add logic to update the timerIntervalSet to insert in between...
//       4. Merge logic to randomize images and splice in the text images.

enum EmbedAction {
    Start = 'Start',
    Pause = 'Pause',
    End = 'End',
    Faster = 'Faster',
    Slower = 'Slower'
}
enum AudioAction {
    Play = 'Play',
    Pause = 'Pause'
}

export enum BufferStatus {
    Init = 'init',
    Buffering = 'buffering',
    Complete = 'complete'
}

interface EmbedState {
    data: WatchData,
    index: number;
    timer?: NodeJS.Timeout | null,
    action: EmbedAction,
    audioAction: AudioAction,
    imageData?: EmbedImage,
    images: HTMLImageElement[]
    timerInterval: number;
    timerIntervalIdx: number;
    timerIntervalSet: TimerIntervalSet[],
    timerArray: [],
    originalIdxArray: [],
    loaded: boolean,
    speedLabel: string,
    completedSession: boolean,
    progressBar: number,
    currentImage: string,
    nextImage: string,
    paramRuntime: number,
    paramModal: boolean,
    bufferStatus: BufferStatus,
    bufferLoaded: number,
    bufferIndex: number,
    bufferRemaining: number,
    bufferFailed: number,
    bufferTotal: number
}

interface EmbedProps {
    data: WatchData
}

export class EmbedPlayer extends Component<EmbedProps, EmbedState> {
    constructor(props: EmbedProps) {
        super(props);

        const params = new URLSearchParams(document.location.search);

        this.state = {
            index: -1,
            data: props.data,
            timer: null,
            action: EmbedAction.Start,
            audioAction: AudioAction.Play,
            timerInterval: 3000,
            timerIntervalIdx: 0,
            timerIntervalSet: [],
            timerArray: [],
            images: [],
            originalIdxArray: [],
            loaded: false,
            speedLabel: "",
            completedSession: false,
            progressBar: 0,
            currentImage: '',
            nextImage: '',
            paramRuntime: parseFloat(params.get("runtime") || '') || 0,
            paramModal: params.get("modal") ? true : false,
            bufferRemaining: props.data.images.length,
            bufferIndex: 0,
            bufferFailed: 0,
            bufferStatus: BufferStatus.Init,
            bufferLoaded: 0,
            bufferTotal: props.data.images.length
        };

        //Bind this to the functions
        this.nextProperty = this.nextProperty.bind(this);
        this.startAnimation = this.startAnimation.bind(this);
        this.stopAnimation = this.stopAnimation.bind(this);
        this.fasterAnimation = this.fasterAnimation.bind(this);
        this.slowerAnimation = this.slowerAnimation.bind(this);
        this.setAnimation = this.setAnimation.bind(this);
        this.audioChange = this.audioChange.bind(this);
        this.openModalImage = this.openModalImage.bind(this);
        this.closeModalImage = this.closeModalImage.bind(this);
        this.toggleBtn = this.toggleBtn.bind(this);
        this.toggleDisabled = this.toggleDisabled.bind(this);
        this.printRemainingTime = this.printRemainingTime.bind(this);
        this.startEmbedPlayer = this.startEmbedPlayer.bind(this);
        this.initFixTimerIntervalSet = this.initFixTimerIntervalSet.bind(this);
        this.setupTimerIntervalSet = this.setupTimerIntervalSet.bind(this);
        this.setupDefaultArray = this.setupDefaultArray.bind(this);
        //this.onRouteChange = this.onRouteChange.bind(this);
        this.addAudioProcess = this.addAudioProcess.bind(this);
        this.nextClick = this.nextClick.bind(this);
        this.refreshProgress = this.refreshProgress.bind(this);

        this.startBuffering = this.startBuffering.bind(this);
        this.loadImage = this.loadImage.bind(this);
        this.loadImageRange = this.loadImageRange.bind(this);

        this.incrementFailedCount = this.incrementFailedCount.bind(this);
        this.incrementLoadedCount = this.incrementLoadedCount.bind(this);
    }

    incrementLoadedCount() {
        this.setState((prevState, props) => ({
            bufferLoaded: prevState.bufferLoaded + 1,
            bufferRemaining: prevState.bufferTotal - prevState.bufferLoaded - 1
        }),this.refreshProgress);
    }
    incrementFailedCount() {
       this.setState((prevState, props) => ({
            bufferFailed: prevState.bufferLoaded + 1,
            bufferTotal: prevState.bufferTotal - 1,
            bufferRemaining: prevState.bufferTotal - 1 - prevState.bufferLoaded
        }), this.refreshProgress);
    }
    async componentDidMount() {
        let audioPlayer = document.getElementById("audioPlayer");
        if (audioPlayer) {
            let source = document.createElement("source");
            source.src = get(this.state.data, 'music', '');
            audioPlayer.appendChild(source);
        }

        await this.startBuffering();
    }

    async afterFirstBuffer() {
        await this.startEmbedPlayer();
        await this.playImagePlayer();
    }

    async componentDidUpdate() {

    }

    componentWillUnmount() {
        let audioPlayer = document.getElementById("audioPlayer") as HTMLAudioElement
        if (audioPlayer && !audioPlayer.paused)
            audioPlayer.pause()
    }

    setupDefaultArray() {
        if (this.state.timerIntervalSet && this.state.timerIntervalSet.length > 0)
            return;

        let initialInterval = this.state.timerInterval  // milliseconds

        let sets: TimerIntervalSet[] = []

        sets.unshift({
            interval: initialInterval,
            label: "1x"
        });

        // 2x
        sets.unshift({
            interval: initialInterval * 0.5,
            label: "2x"
        });

        // 3x
        sets.unshift({
            interval: initialInterval * 0.3333333333,
            label: "3x"
        });

        // 4x
        sets.unshift({
            interval: initialInterval * 0.25,
            label: "4x"
        });

        // 5x
        sets.unshift({
            interval: initialInterval * 0.2,
            label: "5x"
        });

        // 6x
        sets.unshift({
            interval: initialInterval * 0.1666666667,
            label: "6x"
        });

        // 7x
        sets.unshift({
            interval: initialInterval * 0.14285714,
            label: "7x"
        });

        // 8x
        sets.unshift({
            interval: initialInterval * 0.125,
            label: "8x"
        });

        // 9x
        sets.unshift({
            interval: initialInterval * 0.11111111111,
            label: "9x"
        });

        // 10x
        sets.unshift({
            interval: initialInterval * 0.1,
            label: "Max"
        });

        this.setState({timerIntervalSet: sets});
    }

    initFixTimerIntervalSet(runtime: number, totalNumberOfImgs: number) {
        this.setupDefaultArray();

        let seconds: number = runtime * 60;
        let interval: number = seconds / totalNumberOfImgs;
        interval *= 1000;

        let timerIntervalSet = this.state.timerIntervalSet;
        let timerIntervalIdx = this.state.timerIntervalIdx;
        let timerInterval = interval;

        for (let i: number = this.state.timerIntervalSet.length - 1; i >= 0; --i) {
            if (this.state.timerIntervalSet[i].interval <= interval) {
                timerIntervalSet[i].interval = interval;
                timerIntervalIdx = i;
                timerInterval = interval;
                break;
            } else if (i === 0 && this.state.timerIntervalSet[i].interval > interval) {
                timerIntervalSet[i].interval = interval;
                timerIntervalIdx = i;
                timerInterval = interval;
            }
        }

        const state = {
            timerInterval: timerInterval,
            timerIntervalIdx: timerIntervalIdx,
            timerIntervalSet: timerIntervalSet
        }
        this.setState(state);
    }

    setupTimerIntervalSet(length: number) {
        const defaultRuntime = 15; //length / 25;
        if (length > 0) {
            let runtime = this.state.paramRuntime || defaultRuntime;

            this.initFixTimerIntervalSet(runtime > 0 ? runtime : defaultRuntime, length);

            this.toggleDisabled();
        }
    }

    async addAudioProcess(src: string): Promise<HTMLAudioElement> {
        return await new Promise((resolve, reject) => {
            let audioPlayer = document.getElementById("audioPlayer") as HTMLAudioElement;

            if (audioPlayer) {
                audioPlayer.onload = () => {
                    resolve(audioPlayer);
                };
                audioPlayer.onerror = reject;
                audioPlayer.src = src;
            }
        });
    }

    async startBuffering(startingIndex: number = 0) {
        let idx: number;

        //load images in blocks
        const perBlock = 100;
        for (idx = startingIndex; idx < this.state.data.images.length; idx += perBlock) {
            const fromIndex = idx;
            const toIndex = idx + perBlock - 1;

            const batch = await this.loadImageRange(fromIndex, toIndex);
            let data: HTMLImageElement[] = this.state.images;
            for (let img of batch)
                if (img instanceof HTMLImageElement)
                    data.push(img);

            this.setState({images:data, loaded: true});
            if (idx === startingIndex)
                this.afterFirstBuffer().catch(function(err){
                    console.log(err);
                });
        }
    }
    async loadImageRange(fromIndex: number, toIndex: number): Promise<(HTMLImageElement | void)[]> {
        if (toIndex >= this.state.data.images.length)
            toIndex = this.state.data.images.length

        const promises: Promise<HTMLImageElement | void>[] = [];

        let idx: number;
        for(idx = fromIndex; idx <= toIndex; idx++)
            promises.push(this.loadImage(idx));

        return await Promise.all(promises);
    }
    async loadImage(idx: number): Promise<HTMLImageElement | void> {
        if (!this.state.data.images[idx])
            return;

        let element: HTMLImageElement = new Image();
        try {
            const src = this.state.data.images[idx].path;
            await new Promise((resolve, reject) => {
                element.onload = () => resolve();
                element.onerror = (err) => reject(err);
                element.src = this.state.data ? this.state.data.base + src : src;
            });
            await this.incrementLoadedCount();
            return element;
        } catch(err) {
            await this.incrementFailedCount();
        }
    }

    refreshProgress() {
         this.setState(function(prevState, props) {
             let progress = Math.round(prevState.bufferLoaded / prevState.bufferTotal * 100);
             return {progressBar: progress < 100 ? progress : 100}
        });
    }

    async startEmbedPlayer() {
        if (this.state.paramModal)
            this.openModalImage();

        const imageArray: EmbedImage[] = this.state.data.images;
        this.setupTimerIntervalSet(imageArray.length);

        let label = this.state.timerIntervalSet[this.state.timerIntervalIdx] ? this.state.timerIntervalSet[this.state.timerIntervalIdx].label : '';
        this.setState({
            action: EmbedAction.Start,
            speedLabel: label
        });
        await this.nextProperty();
    }

    async playImagePlayer() {
        for (let i = 0; i < 1000; i++) {
            clearInterval(i);
        }

        const timer = setInterval(function() {
            const nextClick = document.querySelector(".next-click") as HTMLDivElement
            if (nextClick)
                nextClick.click();
        }, this.state.timerInterval);

        this.setState({timer: timer});

        await this.toggleBtn();
    }

    // TODO: Use the carousel way instead
    async nextProperty() {

        let action: EmbedAction = this.state.action;
        let index: number = this.state.index + 1;

        //stop animation if no more properties
        if (this.state.index >= (this.state.images.length - 1)) {
            await this.setState({
                action: EmbedAction.End,
                completedSession: true,
                currentImage: this.state.images[0].src,
                nextImage: this.state.images[1].src
            });
            this.stopAnimation();
            return;
        }

        if (this.state.action === EmbedAction.Slower || this.state.action === EmbedAction.Faster) {
            await this.setState({
                index: index,
                action: EmbedAction.Pause
            });
            return;
        }

        let current: HTMLImageElement = this.state.images[index]
        let next: HTMLImageElement = this.state.images[index]

        this.setState({
            index: index,
            currentImage: current ? current.src : '',
            nextImage: next ? next.src : '',
        });



    }


    stopAnimation() {
        if (this.state.action !== EmbedAction.End)
            this.setState({action: EmbedAction.Pause});

        // NOTE: Funkie way of clearing the timer for now
        // TODO: Remove this after adding in a structure to keep track of timer creation.
        for (let i = 0; i < 1000; i++) {
            clearInterval(i);
        }

        if (this.state.timer) {
            clearInterval(this.state.timer);
            this.setState({timer: null});
        }

        let audioPlayer = document.getElementById("audioPlayer") as HTMLAudioElement;
        if (audioPlayer && !audioPlayer.paused)
            audioPlayer.pause();


        let playBtns = document.querySelectorAll(".fa-play");
        playBtns.forEach(function(element) {
            element.classList.remove("hidden");
        });

        let pauseBtns = document.querySelectorAll(".fa-pause");
        pauseBtns.forEach(function(element) {
            element.classList.add("hidden");
        });
    }

    nextClick() {
        const nextClick = document.querySelector(".next-click") as HTMLDivElement;
        if (nextClick)
            nextClick.click();
    }

    startAnimation() {
        const nextClick = this.nextClick.bind(this)

        const timer: NodeJS.Timeout = setInterval(function() {
            nextClick()
        }, this.state.timerInterval);
        this.setState({
            action: EmbedAction.Start,
            timer: timer
        }, function(){
            nextClick();

            let playBtns = document.querySelectorAll(".fa-play");
            playBtns.forEach(function(element) {
                element.classList.add("hidden");
            });

            let pauseBtns = document.querySelectorAll(".fa-pause");
            pauseBtns.forEach(function(element) {
                element.classList.remove("hidden");
            });
        })

        this.playAudio();





    }

    slowerAnimation() {
        if (this.state.action === EmbedAction.End) {
            this.stopAnimation();
            return;
        }
        this.setAnimation(this.state.timerIntervalIdx + 1, EmbedAction.Slower);
    }

    fasterAnimation() {

        if (this.state.action === EmbedAction.End) {
            this.stopAnimation();
            return;
        }
        this.setAnimation(this.state.timerIntervalIdx - 1, EmbedAction.Faster);
    }

    setAnimation(idx: number, nextAction: EmbedAction) {

        const currentAction:EmbedAction = this.state.action;

        const set = this.state.timerIntervalSet[idx];
        if (!set)
            return;

        this.setState({
            timerIntervalIdx: idx,
            timerInterval: set.interval,
            speedLabel: set.label,
            action: (this.state.action === EmbedAction.Start) ? nextAction : this.state.action
        });

        if (currentAction === EmbedAction.Start) {
            this.stopAnimation();
            this.startAnimation();
        }

        this.toggleDisabled();
    }


    audioChange() {
        let volumeUpBtns = document.querySelectorAll(".fa-volume-up");
        let volumeMuteBtns = document.querySelectorAll(".fa-volume-mute");

        if (volumeUpBtns[0].classList.contains("hidden")) {
            this.setState({ audioAction: AudioAction.Play });
            volumeUpBtns.forEach(function(element) {
                element.classList.remove("hidden");
            });

            volumeMuteBtns.forEach(function(element) {
                element.classList.add("hidden");
            });

            this.playAudio();
        } else {
            this.setState({ audioAction: AudioAction.Pause });
            volumeUpBtns.forEach(function(element) {
                element.classList.add("hidden");
            });

            volumeMuteBtns.forEach(function(element) {
                element.classList.remove("hidden");
            });

            this.pauseAudio();
        }
    }

    audioIsPaused() {
        let audioPlayer = document.getElementById("audioPlayer") as HTMLAudioElement;
        if (audioPlayer)
            return audioPlayer.paused;
        return false;
    }

    playAudio() {
        let audioPlayer = document.getElementById("audioPlayer") as HTMLAudioElement;
        if (audioPlayer && audioPlayer.paused && this.state.audioAction === AudioAction.Play) {
            const playPromise = audioPlayer.play();
            if (playPromise !== null) {
                playPromise.catch(error => {
                    audioPlayer.play().then(function(){

                    })
                });
            }
        }
    }

    pauseAudio() {
        let audioPlayer = document.getElementById("audioPlayer") as HTMLAudioElement;
        if (audioPlayer && !audioPlayer.paused)
            return audioPlayer.pause();
    }

    openModalImage() {
        if (this.state.timer)
            this.playAudio()

        this.setModalVisibility(true);
    }

    closeModalImage() {
        this.setModalVisibility(false);
    }

    setModalVisibility(visible: boolean) {
        let modalImgPlayer = document.getElementById("modalEmbedPlayer");
        if (modalImgPlayer)
            modalImgPlayer.style.display = visible ? "block" : "none";

        let mainImgPlayer = document.getElementById("mainEmbedPlayer");
        if (mainImgPlayer)
            mainImgPlayer.style.display = visible ? "none" : "block";
    }

    toggleBtn() {
        if (this.state.action === EmbedAction.Pause || this.state.action === EmbedAction.End)
            this.startAnimation();
        else
            this.stopAnimation();
    }

    toggleDisabled() {
        const downBtns = document.querySelectorAll(".fa-angle-double-down");
        if (this.state.timerIntervalIdx === this.state.timerIntervalSet.length - 1) {
            downBtns.forEach(function(element) {
                element.classList.add("disabled");
            });
        } else {
            downBtns.forEach(function(element) {
                element.classList.remove("disabled");
            });
        }

        const upBtns = document.querySelectorAll(".fa-angle-double-up");
        if (this.state.timerIntervalIdx === 0) {
            upBtns.forEach(function(element) {
                element.classList.add("disabled");
            });
        } else {
            upBtns.forEach(function(element) {
                element.classList.remove("disabled");
            });
        }
    }

    printRemainingTime() {
        const {
            timerInterval,
            index
        } = this.state;

        let imageArray: EmbedImage[] = this.state.data ? this.state.data.images : [];

        let count = imageArray.length - 1 - index;
        let timeRemainMS = count * timerInterval

        // convert to HH:MM:SS

        return this.formatMS(timeRemainMS);
    }

    formatMS(ms: number) {
        // 1- Convert to seconds:
        let delta: number = Math.ceil(ms / 1000);

        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        // what's left is seconds
        const seconds = delta % 60;

        if (seconds)
        return (
            this.formatNumber(hours) +
            ":" +
            this.formatNumber(minutes) +
            ":" +
            this.formatNumber(Math.round(seconds))
        );
    }

    formatNumber(num:number): string {
        let format:string = num.toString();
        return format.padStart(2, '0')
    }

    render() {

            const images: EmbedImage[] = this.state.data ? this.state.data.images : [];

            return (
                <div className="minWidth">
                    <div id="mainEmbedPlayer" className="image-player">
                        <div className="image-title">{get(this.state,'data.session.name','')}</div>
                        <div>
                            <div className="next-click" onClick={this.nextProperty} />
                            {/* <div id="ppAfterSurvey" onClick={this.onClickAfterSurvey} /> */}
                        </div>

                        {/* <canvas id="ppCanvas" style={{ display: 'none' }} /> */}
                        {/* <div>{this.renderTemplateImages()}</div> */}

                        <div className="d-flex justify-content-center">
                            <div className="bg-white p-2 shadow">
                                <div className="image-container position-relative">
                                    <EmbedDetail
                                        name={this.state.data ? this.state.data.session.name : ''}
                                        imageIndex={this.state.originalIdxArray[this.state.index] + 1}
                                        progressBar={this.state.progressBar}
                                        loaded={this.state.loaded}
                                        current={this.state.currentImage}
                                        next={this.state.nextImage}
                                    />
                                    <div
                                        id="embedPlayerControls"
                                        className={"player-control d-flex align-items-center p-2" + (this.state.action === EmbedAction.End ? " hidden":"")}
                                    >
                    <span
                        id="playBtn"
                        className="md-font p-2"
                        onClick={this.toggleBtn}
                    >
                      {/* <i className="fas fa-play white hidden" /> */}
                        <FontAwesomeIcon
                            icon="play"
                            className="hidden"
                            color="white"
                        />
                      <FontAwesomeIcon icon="pause" color="white"/>
                    </span>

                                        <div
                                            id="slowBtn"
                                            className="d-flex align-items-center sm-font p-2"
                                            onClick={this.slowerAnimation}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Slower"
                                        >
                                            {/* <i className="fas fa-angle-double-down white" /> */}
                                            <p className="sm-font text-white m-0">slower</p>
                                            <FontAwesomeIcon
                                                icon="angle-double-down"
                                                className="ml-2"
                                                color="white"
                                            />
                                        </div>

                                        <div
                                            id="fastBtn"
                                            className="d-flex align-items-center sm-font p-2"
                                            onClick={this.fasterAnimation}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Faster"
                                        >
                                            {/* <i className="fas fa-angle-double-up white" /> */}
                                            <p className="sm-font text-white m-0">faster</p>
                                            <FontAwesomeIcon
                                                icon="angle-double-up"
                                                className="ml-2"
                                                color="white"
                                            />
                                        </div>

                                        <span
                                            id="speedLabel"
                                            // className="speed-txt"
                                            className="sm-font p-2"
                                            style={{fontWeight: "bold"}}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Speed of Animation"
                                        >
                      <div className="white-text">{this.state.speedLabel}</div>
                    </span>

                                        <span
                                            id="timeRemainLabel"
                                            // className="time-remain-txt"
                                            className="ml-auto sm-font p-2"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Time Remaining"
                                        >
                      <div className="white-text">
                        {this.printRemainingTime()}
                      </div>
                    </span>

                                        <span
                                            id="audioBtn"
                                            className="ml-auto md-font p-2"
                                            onClick={this.audioChange}
                                        >
                      <FontAwesomeIcon
                          icon="volume-mute"
                          className="hidden"
                          color="white"
                      />
                      <FontAwesomeIcon icon="volume-up" color="white"/>
                    </span>

                                        <span
                                            id="expandBtn"
                                            className="md-font p-2"
                                            onClick={this.openModalImage}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Expand Player"
                                        >
                      {/* <i className="fas fa-expand white" /> */}
                                            <FontAwesomeIcon icon="expand" color="white"/>
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="modalEmbedPlayer">
                        <div id="modalEmbedPlayerContent">
                            <ModalEmbedDetail
                                name={this.state.data ? this.state.data.session.name : ''}
                                loaded={this.state.loaded}
                                imageIndex={this.state.originalIdxArray[this.state.index] + 1}
                                current={this.state.currentImage}
                                next={this.state.nextImage}
                            />
                            <div
                                id="modalEmbedPlayerControls"
                                className="modal-player-control d-flex align-items-center p-2"
                            >
                <span
                    id="modalPlayBtn"
                    className="md-font p-2"
                    onClick={this.toggleBtn}
                >
                  <FontAwesomeIcon icon="play" className="hidden" color="white"/>
                  <FontAwesomeIcon icon="pause" color="white"/>
                </span>

                                <span
                                    id="modalSlowBtn"
                                    className="sm-font d-flex align-items-center p-2"
                                    onClick={this.slowerAnimation}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Slower"
                                >
                  <p className="sm-font text-white m-0">slower</p>
                  <FontAwesomeIcon
                      className="ml-2"
                      icon="angle-double-down"
                      color="white"
                  />
                </span>

                                <span
                                    id="modalFastBtn"
                                    className="sm-font d-flex align-items-center p-2"
                                    onClick={this.fasterAnimation}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Faster"
                                >
                  <p className="sm-font text-white m-0">faster</p>
                  <FontAwesomeIcon
                      className="ml-2"
                      icon="angle-double-up"
                      color="white"
                  />
                </span>

                                <span
                                    id="speedLabel"
                                    // className="speed-txt"
                                    className="sm-font p-2"
                                    style={{fontWeight: "bold"}}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Speed of Animation"
                                >
                  <div className="white-text">{this.state.speedLabel}</div>
                </span>

                                <span
                                    id="timeRemainLabel"
                                    // className="time-remain-txt"
                                    className="sm-font ml-auto"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Time Remaining"
                                >
                  <div className="white-text">{this.printRemainingTime()}</div>
                </span>

                                <span
                                    id="modalAudioBtn"
                                    className="md-font ml-auto p-2"
                                    onClick={this.audioChange}
                                >
                  <FontAwesomeIcon
                      icon="volume-mute"
                      className="hidden"
                      color="white"
                  />
                  <FontAwesomeIcon icon="volume-up" color="white"/>
                </span>

                                <span
                                    id="compressBtn"
                                    className="md-font p-2"
                                    // style={{ position: 'fixed' }}
                                    onClick={this.closeModalImage}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Exit Fullscreen"
                                >
                  {" "}
                                    <FontAwesomeIcon icon="compress" color="white"/>
                </span>
                            </div>
                        </div>
                        <span className="modal-close cursor" onClick={this.closeModalImage}>
              &times;
            </span>
                    </div>

                    <div id="audioContent">
                        {/* <audio id="audioPlayer" autoPlay={false} loop preload="auto"> */}
                        <audio id="audioPlayer" loop preload="auto">
                            {/* <source
                       src={
                          process.env.REACT_APP_S3_URL +
                          this.state.defaultAudioUrl
                       }
                       type="audio/mpeg"
                    /> */}
                            <p>If you're reading this, audio isn't supported.</p>
                        </audio>
                    </div>
                </div>
            );
        }
}






